/* Hover Scroll Effect */
.frame {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.frame img {
    max-width: none;
  object-fit: cover;
  heigth:100%;
  transform: translateX(0);
  transition: 10s ease-out;
}
.frame:hover img, .frame:active img {
  object-fit: cover;
  heigth: 300px;
  /* Considering frame height   */
  transform: translateX(calc(-100% + 500px));
  transition: 20s ease-out;
}
